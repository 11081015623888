import React from 'react'
import { Link } from 'gatsby'
import SiteMetadata from './SiteMetadata'
import ContactForm from './shortcodes/ContactForm'
import Map from './Map'
import FooterStyles from '../styles/Footer.module.sass'

/**
 * Display the global footer
 *
 * @param {boolean} [showContactForm=true]
 */
const Footer = props => {
  const { title } = SiteMetadata()

  return (
    <>
      {(props.showContactForm !== 'false' && props.showContactForm !== false) && (
        <div
          className={`is-clearfix contact-form-holder ${FooterStyles.contactFormHolder || ''}`}
        >
          <div
            className={`container is-semi-narrow ${FooterStyles.contactFormContainer || ''}`}
          >
            <ContactForm className={FooterStyles.form} />
            <div className={FooterStyles.mapHolder}>
              <Map className={FooterStyles.map} />
              <div className={FooterStyles.directions}>
                <strong>Address:</strong>
                <p>Unit 5C, Kylemore Park North, Dublin 10 — D10 TX39</p>
                <a href="https://goo.gl/maps/ijXpDWpzPWczBHXQA" target="_blank" rel="noreferrer noopener" className="tag is-pill">View on Map</a>
              </div>
            </div>
          </div>
        </div>
      )}

      <footer className={`footer ${FooterStyles.footer || ''}`}>
        <div className="container has-text-centered is-semi-narrow">
          <p>
            Copyright{' '}
            <span className="current-year">{new Date().getFullYear()}</span>{' '}
            <strong>{title}</strong>. All rights reserved. | <Link to="/blog/">Blog</Link>
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
